<template>
  <section>
    <b-overlay :show="loading">
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- Col: Left (pedido Container) -->
            <b-col
              cols="12"
              lg="10"
              xl="10"
              md="12"
            >
              <b-card
                no-body
                :aria-hidden="loading ? 'true' : null"
              >
                <!-- Head er -->
                <b-card-body class="pb-0">
                  <div
                    class="d-flex justify-content-between flex-md-row flex-column mt-0"
                  >
                    <!-- Header: Left Content -->
                    <div>
                      <h3
                        v-if="pedidoData.codigo"
                        class="text-primary"
                      >
                        Codigo #{{ pedidoData.codigo }}
                      </h3>

                      <h3
                        v-else
                        class="text-primary"
                      >
                        Nuevo pedido
                      </h3>

                      <b-card-text class="mb-1">
                        <strong> Creado por: </strong>
                        {{ pedidoData.user_creado_name || user.name }}
                      </b-card-text>
                      <validation-provider
                        #default="validationContext"
                        name="Departamento"
                        rules="required"
                      >
                        <b-form-group
                          label="Departamento*"
                          label-for="Departamento"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="pedidoData.fk_departamento"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="departamentos || []"
                            :clearable="false"
                            class="item-selector-title select-departamentos"
                            placeholder="Selecionar"
                            :reduce="(dep) => dep.key"
                            :disabled="cantEditPedido"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <b-row
                        align-h="end"
                        class="pr-1 mb-2"
                      >
                        <b-badge :variant="statusVariant(pedidoData.estado)">{{
                          statusLabel(pedidoData.estado)
                        }}</b-badge>
                      </b-row>
                      <div class="d-flex align-items-center mb-1">
                        <span class="title pr-1 pb-1"> Fecha*: </span>
                        <validation-provider
                          #default="validationContext"
                          name="Fecha"
                          rules="required"
                        >
                          <b-form-group
                            :state="getValidationState(validationContext)"
                          >
                            <flat-pickr
                              v-if="!cantEditPedido"
                              v-model="pedidoData.fecha"
                              placeholder="dd-mm-yyyy"
                              class="form-control active_es"
                              :config="{
                                allowInvalidPreload: true,
                                format: 'd-m-Y',
                                altFormat: 'd-m-Y',
                                altInput: true,
                                allowInput: true,
                                locale: Spanish,
                                disable: [
                                  function (date) {
                                    return date < new Date();
                                  },
                                ],
                              }"
                              :disabled="cantEditPedido"
                            />
                            <b-form-input
                              v-else
                              v-model="pedidoData.fecha"
                              disabled
                            />
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>
                      <b-row
                        align-h="end"
                        class="mx-0"
                      >
                        <b-form-checkbox
                          v-model="pedidoData.is_urgent"
                          :disabled="cantEditPedido"
                        >Urgente</b-form-checkbox>
                      </b-row>
                    </div>
                  </div>
                </b-card-body>

                <!-- Descripcion -->
                <b-card-body class="pedido-padding pt-0">
                  <validation-provider
                    #default="validationContext"
                    name="Descripcion"
                    rules="required"
                  >
                    <b-form-group
                      label="Descripcion*"
                      label-for="Descripcion"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-textarea
                        v-model="pedidoData.descripcion"
                        :disabled="cantEditPedido"
                      /><b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-card-body>

                <!-- Spacer -->
                <hr>

                <h3 class="pl-2 mb-1">
                  Detalles del pedido
                </h3>

                <b-table
                  :items="pedidoItems"
                  responsive
                  :fields="tableHeaders"
                  class="position-relative rows-responsive detalles"
                  stacked="sm"
                >
                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <b-row>
                      <!-- Eliminar -->
                      <b-button
                        v-if="!cantEditPedido"
                        :id="`delete-${data.item.id}`"
                        size="sm"
                        variant="danger"
                        class="btn-icon mr-1"
                        @click="removeItem(data.index, data.item)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                      <b-tooltip
                        v-if="!cantEditPedido"
                        :target="`delete-${data.item.id}`"
                        triggers="hover"
                        placement="bottom"
                        boundary="window"
                      >
                        <b>Eliminar</b>
                      </b-tooltip>

                      <!-- Do a order -->
                      <b-button
                        v-if="
                          (pedidoData.estado === 'aceptado') &&
                            $store.state.auth.user.fk_role === 1 &&
                            !data.item.ignored
                        "
                        :id="`order-${data.item.id}`"
                        size="sm"
                        variant="primary"
                        @click="orderItem(data.item)"
                      >
                        <feather-icon icon="ShoppingCartIcon" />
                      </b-button>
                    </b-row>
                  </template>

                  <!-- Descripcion -->
                  <template #cell(descripcion)="data">
                    <validation-provider
                      #default="validationContext"
                      name="Descripcion-Material"
                      rules="required"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <div style="display: flex; flex-direction: row">
                          <v-select
                            v-model="data.item.descripcion"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="filteredMateriales"
                            label="descripcion"
                            :clearable="false"
                            placeholder="Select Item"
                            :disabled="cantEditPedido || data.item.ignored || isMovil"
                            append-to-body
                            :style="`min-width: 500px; ${
                              data.item.ignored
                                ? 'text-decoration: line-through; color: red'
                                : ''
                            }`"
                            @input="(val) => updateItemForm(data.index, val)"
                          />
                          <router-link
                            v-if="data.item.id"
                            target="_blank"
                            style="padding: 6px 0 0 6px"
                            :to="{ name: 'material-edit', params: { id: data.item.id } }"
                          >
                            <slot>
                              <feather-icon
                                icon="ExternalLinkIcon"
                                size="16"
                              />
                            </slot>
                          </router-link>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </template>

                  <!-- Tipo Medida -->
                  <template #cell(medida)="data">
                    <span
                      :style="`${
                        data.item.ignored
                          ? 'text-decoration: line-through; color: red'
                          : ''
                      }`"
                    >{{ data.item.medida }}</span>
                  </template>

                  <!-- Coste -->
                  <template #cell(coste)="data">
                    <span
                      :style="`${
                        data.item.ignored
                          ? 'text-decoration: line-through; color: red'
                          : ''
                      }`"
                    >{{ data.item.coste.toFixed(2) }}€</span>
                  </template>

                  <!-- Stock -->
                  <template #cell(stock)="data">
                    <b-badge
                      v-if="data.item.stock <= 0"
                      :style="`${
                        data.item.ignored
                          ? 'text-decoration: line-through; color: red'
                          : ''
                      }`"
                      variant="danger"
                    >
                      Sin Stock
                    </b-badge>
                    <b-badge
                      v-else-if="data.item.stock < data.item.unidades"
                      :style="`${
                        data.item.ignored
                          ? 'text-decoration: line-through; color: red'
                          : ''
                      }`"
                      variant="warning"
                    >
                      {{ data.item.stock }} insuficiente
                    </b-badge>
                    <span
                      v-else
                      :style="`${
                        data.item.ignored
                          ? 'text-decoration: line-through; color: red'
                          : ''
                      }`"
                    >{{ data.item.stock }}</span>
                  </template>

                  <!-- Coste Total -->
                  <template #cell(coste_total)="data">
                    <span
                      :style="`${
                        data.item.ignored
                          ? 'text-decoration: line-through; color: red'
                          : ''
                      }`"
                    >{{
                      data.item.coste
                        ? (data.item.coste * data.item.unidades).toFixed(2)
                        : 0
                    }}€</span>
                  </template>

                  <template #cell(fk_pedido_proveedor)="data">
                    <!-- Check if order has been made -->
                    <router-link
                      v-if="data.item.fk_pedido_proveedor"
                      :to="{ name: 'pedido-proveedor-edit', params: { id: data.item.fk_pedido_proveedor } }"
                    >
                      <slot>{{ data.item.pedido_proveedor_codigo }}</slot>
                    </router-link>
                    <span v-else>-</span>
                  </template>

                  <!-- Cantidad -->
                  <template #cell(unidades)="data">
                    <validation-provider
                      #default="validationContext"
                      name="Cantidad"
                      rules="required|min_value:1"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="data.item.unidades"
                          type="number"
                          :disabled="cantEditPedido || data.item.ignored"
                          :style="`${
                            data.item.ignored
                              ? 'text-decoration: line-through; text-decoration-color: red'
                              : ''
                          }`"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </template>

                </b-table>

                <b-row class="px-1">
                  <!-- Items Section -->
                  <b-card-body class="form-item-section">
                    <b-button
                      v-if="!cantEditPedido"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      @click="selectMaterialModal = true"
                    >
                      Añadir Material
                    </b-button>
                  </b-card-body>

                  <b-card-body v-if="user.fk_role === 1">
                    <h4>
                      <strong>Total:</strong>
                      {{ calculateTotal() }}€
                    </h4>
                    <h5
                      v-if="calculateTotalIgnored() > 0"
                      style="color: red"
                    >
                      <strong>Total rechazado:</strong>
                      {{ calculateTotalIgnored() }}€
                    </h5>
                  </b-card-body>
                </b-row>

                <!-- Spacer -->
                <hr>

                <!-- Note -->
                <b-card-body class="pedido-padding pt-0">
                  <span class="font-weight-bold">Nota: </span>
                  <b-form-textarea
                    v-model="pedidoData.nota"
                    :disabled="cantEditPedido"
                  />
                </b-card-body>
              </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col
              cols="12"
              lg="2"
              md="3"
              xl="2"
              class="pedido-actions mt-md-0 mt-2"
            >
              <!-- Action Buttons -->
              <b-card>
                <h5
                  v-if="pedidoData.estado === 'pendiente' && isOperario"
                  class="text-center"
                >
                  Esperando la aprobación de la gerencia
                </h5>

                <h5
                  v-if="pedidoData.estado === 'aceptado' && isOperario"
                  class="text-center"
                >
                  Pedido aceptado! Se generará un albarán por parte de la
                  administración
                </h5>
                <b-card-text
                  v-if="pedidoData.estado === 'aceptado'"
                  class="mb-1"
                >
                  <strong> Aprobado por: </strong>
                  {{ pedidoData.user_aprobado_name }}
                </b-card-text>

                <h5
                  v-if="pedidoData.estado === 'cancelado'"
                  class="text-center"
                >
                  Pedido cancelado!
                </h5>

                <!-- Button: Save pedido -->
                <b-button
                  v-if="!cantEditPedido"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-75"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  {{
                    pedidoData.estado === "borrador"
                      ? "Guardar borrador"
                      : "Guardar"
                  }}
                </b-button>

                <!-- Button: Send pedido -->
                <b-button
                  v-if="
                    $route.params.id !== 'new' &&
                      pedidoData.estado === 'borrador'
                  "
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mb-75"
                  block
                  :disabled="invalid"
                  @click="sendPedidoToEvaluation()"
                >
                  Guardar y enviar
                </b-button>

                <!-- Button: Delete -->
                <b-button
                  v-if="pedidoData.estado === 'borrador'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="danger"
                  block
                  @click="deleteModal = true"
                >
                  Eliminar
                </b-button>

                <!-- Button: Negar Pedido -->
                <b-button
                  v-if="pedidoData.estado === 'pendiente' && isAdmin"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="danger"
                  block
                  @click="denyPedido()"
                >
                  Negar
                </b-button>

                <!-- Button: Aprovar Pedido -->
                <b-button
                  v-if="pedidoData.estado === 'pendiente' && isAdmin"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="success"
                  block
                  @click="saveAndApprovePedido()"
                >
                  Aprobar
                </b-button>
<!--
                &lt;!&ndash; Button: Revertir estado Pedido &ndash;&gt;
                <b-button
                  v-if="pedidoData.estado === 'aceptado' && $store.state.auth.user.id === 1"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="danger"
                  block
                  @click="revertStatusPedido()"
                >
                  Revertir a pendiente
                </b-button>-->

                <!-- Button: Crear Albaran -->
                <b-button
                  v-if="pedidoData.estado === 'aceptado' && isAdmin && !pedidoData.fk_albaran"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  block
                  @click="createAlbaran()"
                >
                  Crear albarán de entrega
                </b-button>
                <b-button
                  v-if="['aceptado', 'entregado'].includes(pedidoData.estado) && isAdmin && pedidoData.fk_albaran"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  block
                  @click="goToAlbaran(pedidoData.fk_albaran)"
                >
                  Ver albarán de entrega
                </b-button>
                <b-button
                  v-if="$route.params.id !== 'new'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  block
                  @click="imprimirPedido(pedidoData.id)"
                >
                  <feather-icon
                    icon="PrinterIcon"
                    class="mr-1"
                  />Imprimir
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
    <b-modal
      v-model="selectMaterialModal"
      :size="isMovil ? ' select-material-movil' : 'xl'"
      title="Seleccionar material"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row
        align-v="start"
        class="ml-0 pl-0"
      >
        <b-col
          cols="12"
          lg="2"
          md="6"
          sm="12"
          xs="12"
        >
          <b-form-select
            id="perPageSelect"
            v-model="materialPerPage"
            :options="materialPerPageOptions"
          />
        </b-col>
        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <b-form-input
            id="filterInput"
            v-model="searchMaterial"
            placeholder="Buscar..."
            class="form-control-merge"
            type="search"
          />
        </b-col>
        <b-col
          cols="12"
          lg="4"
          md="6"
          sm="12"
          xs="12"
        >
          <b-row
            align-h="end"
            class="mx-0 px-0"
          >
            <b-col
              cols="12"
              lg="6"
            >
              <b-button
                v-if="isMovil"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="warning"
                class="btn-icon"
                @click="selectMaterialModal = false"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                v-else
                block
                variant="danger"
                @click="selectMaterialModal = false"
              >
                Cancelar
              </b-button>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-button
                v-if="isMovil"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                block
                @click="addNewItemInItemForm"
              >
                <feather-icon icon="SaveIcon" />
              </b-button>
              <b-button
                v-else
                block
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mx-0 px-0 pt-2">
        <b-col
          cols="12"
          lg="5"
          md="5"
          sm="6"
          xs="12"
        >
          <v-select
            v-model="proveedorFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="proveedores"
            :reduce="(val) => val.id"
            :clearable="false"
            class="mb-1 item-selector-title"
            placeholder="Proveedor"
            label="nombre_comercial"
          />
        </b-col>
        <b-col
          cols="12"
          lg="5"
          md="5"
          sm="6"
          xs="12"
        >
          <v-select
            v-model="tipoFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="tipos"
            :clearable="false"
            class="mb-1 item-selector-title"
            placeholder="Tipo Material"
          />
        </b-col>
        <b-col
          cols="12"
          lg="2"
          md="2"
          sm="12"
          xs="12"
        >
          <b-button
            block
            variant="outline-danger"
            @click="clearMaterialFilter"
          >
            Limpar Filtro
          </b-button>
        </b-col>
      </b-row>
      <b-table
        :items="materialesForTableFilter"
        responsive
        :fields="materialTableHeaders"
        :current-page="currentPageMaterial"
        :per-page="materialPerPage"
        class="position-relative rows-responsive"
        selectable
        select-mode="multi"
        select-variant="light"
        :tbody-tr-class="rowClass"
        :filter="searchMaterial"
        @row-clicked="onRowSelected"
        stacked="sm"
      >
        <template #cell(selected)="data">
          <template v-if="data.item.selected">
            <feather-icon
              size="20"
              icon="CheckCircleIcon"
            />
          </template>

          <template v-else>
            <feather-icon
              size="20"
              icon="CircleIcon"
            />
          </template>
        </template>

        <!-- Column: Codigo -->
        <template #cell(codigo)="data">
          #{{ data.item.codigo }}
        </template>
        <!-- Column: Descripción -->
        <template #cell(descripcion)="data">
          {{
            data.item.descripcion_custom
              ? data.item.descripcion_custom
              : data.item.descripcion
          }}
        </template>

        <!-- Column: Coste -->
        <template #cell(coste)="data">
          <span>{{ data.item.coste }}€</span>
        </template>

        <!-- Column: Estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.estado) }}
          </b-badge>
        </template>

        <!-- Cantidad -->
        <template #cell(unidades)="data">
          <validation-provider
            #default="validationContext"
            name="Cantidad"
            rules="min_value:1"
          >
            <b-form-group :state="getValidationState(validationContext)">
              <b-form-input
                v-model="data.item.unidades"
                placeholder="Units"
                type="number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPageMaterial"
        :total-rows="materialesForTableFilter.length"
        :per-page="materialPerPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      />
    </b-modal>

    <ConfirmationModal
      :id="`confirmation-${$route.params.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el pedido."
      title="¿Está seguro de que desea eliminar el pedido?"
      @action="deletePedido($route.params.id)"
      @close="deleteModal = false"
    />

    <ConfirmationModal
      :id="`confirmation-pedido-proveedor-${$route.params.id}`"
      :show="pedidoProveedorModal"
      message="¿Está seguro de que desea pedir este producto a los proveedores?"
      title="Ordenar producto"
      @action="confirmOrderPedido()"
      @close="pedidoProveedorModal = false"
    />

    <AlertModal
      :id="`alert-${$route.params.id}`"
      :show="alertModal"
      :message="alertMsg"
      :title="alertTitle"
      variant="danger"
      :is-ok-only="true"
      @close="alertModal = false"
      @action="alertModal = false"
    />
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BBadge,
  BTable,
  BTooltip,
  BFormInvalidFeedback,
  BOverlay,
  BLink,
  BModal,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { Spanish } from 'flatpickr/dist/l10n/es'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Pedido from '@/models/Pedido'
import PedidoDetalle from '@/models/PedidoDetalle'
import departamentoStoreModule from '@/views/system/departamento/departamentoStoreModule'
import useDepartamentosList from '@/views/system/departamento/useDepartamentosList'
import useMaterialesList from '@/views/almacen/materiales/useMaterialesList'
import materialesStoreModule from '@/views/almacen/materiales/materialesStoreModule'
import { pedidos } from '@/utils/pedidos'
import router from '@/router'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import albaranesStoreModule from '@/views/almacen/albaranes/albaranesStoreModule'
import Albaran from '@/models/Albaran'
import AlbaranDetalle from '@/models/AlbaranDetalle'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import AlertModal from '@/components/AlertModal.vue'
import PedidoProveedor from '@/models/PedidoProveedor'
import pedidosProveedoresStoreModule from '@/views/almacen/pedidosProveedores/pedidosProveedoresStoreModule'
import PedidoProveedorDetalle from '@/models/PedidoProveedorDetalle'
import { isMobile } from 'mobile-device-detect'
import { materiales } from '@/utils/materiales'
import useProveedoresList from '@/views/system/proveedor/useProveedoresList'
import proveedorStoreModule from '@/views/system/proveedor/proveedorStoreModule'
import pedidosStoreModule from './pedidosStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '../../../@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BBadge,
    BPopover,
    BTable,
    BTooltip,
    flatPickr,
    vSelect,
    Logo,
    ConfirmationModal,
    BFormInvalidFeedback,
    AlertModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BLink,
    BModal,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      Spanish,
      deleteModal: false,
      selectedItem: {},
      required,
      alertModal: false,
      alertMsg: '',
      alertTitle: '',
      pedidoProveedorModal: false,
      pedidoProveedor: null,
      selectMaterialModal: false,
      materialTableHeaders: [
        { key: 'selected', label: '', sortable: true },
        { key: 'codigo', label: 'Código', sortable: true },
        { key: 'descripcion', label: 'Descripción', sortable: true },
        { key: 'unidades', label: 'Cantidad', sortable: false },
        { key: 'coste', label: 'Coste', sortable: true },
        { key: 'stock', label: 'Stock', sortable: true },
        { key: 'tipo', label: 'Tipo Material', sortable: true },
        { key: 'medida', label: 'Medida', sortable: true },
        { key: 'estado', label: 'Estado', sortable: true },
      ],
      currentPageMaterial: 1,
      selected: [],
      filterMaterial: null,
      searchMaterial: '',
      materialPerPage: 10,
      materialPerPageOptions: [10, 20, 50],
      isMovil: isMobile,
      proveedorFilter: null,
      tipoFilter: null,
      tipos: materiales.tipos,
    }
  },
  computed: {
    filteredMateriales() {
      return this.materiales
        .map(material => ({
          id: material.id, // Ajusta según la estructura de tu objeto material
          descripcion: material.descripcion_custom
            ? material.descripcion_custom
            : material.descripcion,
          coste: material.coste,
        }))
        .filter(
          material => !this.pedidoItems.some(y => y.fk_material === material.id),
        )
    },
    materialesForTableFilter() {
      let filterItems = []
      if (this.materiales) {
        filterItems = this.materiales.filter(
          material => material.descripcion
            ?.toLowerCase()
            .includes(this.searchMaterial.toLowerCase())
            || material.descripcion_custom
              ?.toLowerCase()
              .includes(this.searchMaterial.toLowerCase())
            || material.codigo
              ?.toLowerCase()
              .includes(this.searchMaterial.toLowerCase()),
        )

        if (this.tipoFilter) {
          filterItems = filterItems.filter(
            material => material.tipo === this.tipoFilter.value,
          )
        }

        if (this.proveedorFilter) {
          filterItems = filterItems.filter(
            material => material.fk_proveedor === this.proveedorFilter,
          )
        }

        // Adicionar ou definir o valor `unidades` para 1 se não estiver presente
        filterItems = filterItems.map(material => ({
          ...material,
          unidades: material.unidades !== undefined ? material.unidades : 1,
        }))
      }

      return filterItems
    },
    user() {
      return this.$store.state.auth.user
    },
    cantEditPedido() {
      if (this.user.fk_role === 1) {
        if (
          this.pedidoData.estado !== 'borrador'
          && this.pedidoData.estado !== 'pendiente'
        ) return true
        return false
      }
      if (this.pedidoData.estado !== 'borrador') return true
      return false
    },
    isAdmin() {
      return this.user.fk_role === 1
    },
    isOperario() {
      return this.user.fk_role === 2
    },
    loading() {
      return this.$store.state['app-pedidos'].loading
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.selected) {
        // eslint-disable-next-line consistent-return
        return 'bg-light'
      }
    },
    onRowSelected(item) {
      // eslint-disable-next-line no-param-reassign
      item.selected = !item.selected
      if (item.selected) {
        this.selected.push(item)
      } else {
        const index = this.selected.findIndex(i => i.id === item.id)
        this.selected.pop(index)
      }
    },
    clearMaterialFilter() {
      this.searchMaterial = ''
      this.proveedorFilter = null
      this.tipoFilter = null
    },
    orderItem(item) {
      this.pedidoProveedor = new PedidoProveedor({
        fk_user_creado: this.pedidoData.fk_user_creado || this.user.id,
        fk_proveedor: item.fk_proveedor,
        total_coste: item.unidades * item.coste,
        is_urgent: this.pedidoData.is_urgent,
        fk_material: item.id,
        fk_pedido_from: this.pedidoData.id,
        unidades: parseInt(item.unidades),
        fecha: this.pedidoData.fecha,
        estado: 'borrador',
        nota: '',
        detalles: [],
      })
      this.pedidoProveedor.detalles.push(
        new PedidoProveedorDetalle({
          fk_material: item.id,
          coste: item.coste,
          unidades: item.unidades,
        }),
      )

      this.pedidoProveedorModal = true
    },

    async confirmOrderPedido() {
      try {
        const pedidoRes = await this.savePedido()

        this.pedidoData.id = pedidoRes.data.id

        this.pedidoProveedor.detalles.forEach(el => {
          el.fk_pedido_from = this.pedidoData.id
        })
        this.pedidoProveedor.fk_proveedor = this.materiales.filter(m => m.id === this.pedidoProveedor.detalles[0].fk_material)[0].fk_proveedor
        const existingPedidoProveedor = await this.$store.dispatch(
          'app-pedidos-proveedores/checkIfPedidoExistsForProveedor',
          this.pedidoProveedor.fk_proveedor,
        )

        if (existingPedidoProveedor !== null) {
          existingPedidoProveedor.detalles.forEach(el => {
            const existingDetalle = this.pedidoProveedor.detalles.find(
              detalle => detalle.fk_material === el.fk_material,
            )
            if (existingDetalle) {
              existingDetalle.unidades = Number(el.unidades) + 1
            } else {
              this.pedidoProveedor.detalles.push(
                new PedidoProveedorDetalle({
                  fk_pedido_from: el.fk_pedido_from,
                  fk_material: el.fk_material,
                  coste: el.coste,
                  unidades: el.unidades,
                  fk_pedido_proveedor: el.fk_pedido_proveedor,
                  id: el.id,
                }),
              )
            }
          })

          this.pedidoProveedor.id = existingPedidoProveedor.id
        }

        const pedidoProveedorRes = await this.$store.dispatch(
          'app-pedidos-proveedores/addPedidoProveedor',
          this.pedidoProveedor,
        )
        this.pedidoItems.forEach(detalle => {
          const existingDetalle = this.pedidoProveedor.detalles.find(
            el => el.fk_material === detalle.id,
          )
          if (existingDetalle) {
            detalle.fk_pedido_proveedor = pedidoProveedorRes.data.id
          }
        })
        await this.savePedido()

        this.refreshComponentData()
      } catch (error) {
        console.error('confirmOrderPedido: Error en la confirmación del pedido', error)
        this.alertModal = false
        this.alertMsg = 'Se ha creado un nuevo pedido'
        this.alertTitle = 'Alerta'
      } finally {
        this.pedidoProveedorModal = false
      }
    },

    async savePedido() {
      if (this.calculateTotal() <= 0) {
        console.warn('savePedido: Total do pedido é zero ou negativo')
        this.alertModal = true
        this.alertMsg = 'Debes seleccionar al menos 1 material y 1 unidad'
        this.alertTitle = 'Campos invalidos'
        return
      }

      if (this.$route.params.id === 'new') {
        this.pedidoData.fk_user_creado = this.user.id
      }

      this.pedidoData.detalles = []
      this.pedidoItems.forEach(el => {
        this.pedidoData.detalles.push(
          new PedidoDetalle({
            unidades: el.unidades,
            descripcion: el.descripcion_custom
              ? el.descripcion_custom
              : el.descripcion,
            coste_unit: el.coste,
            fk_material: el.id,
            fk_proveedor: el.fk_proveedor,
            fk_pedido_proveedor: el.fk_pedido_proveedor,
            ignored: el.ignored,
          }),
        )
      })

      this.pedidoData.total_coste = this.calculateTotal()

      try {
        const result = await store.dispatch(
          'app-pedidos/addPedido',
          this.pedidoData,
        )
        return result
      } catch (error) {
        console.error('savePedido: Erro ao salvar o pedido', error)
        this.alertModal = true
        this.alertMsg = 'Erro ao salvar o pedido. Tente novamente.'
        this.alertTitle = 'Erro'
        throw error
      }
    },
    calculateTotal() {
      let total = 0
      const coste_material = 0
      this.pedidoItems.forEach(item => {
        if (!item.ignored)
        // count only items that were not cancelled
        { total += item.coste * item.unidades }
      })
      return parseFloat(total.toFixed(2))
    },
    calculateTotalIgnored() {
      let total = 0
      const coste_material = 0
      this.pedidoItems.forEach(item => {
        if (item.ignored)
        // count only items that were cancelled
        { total += item.coste * item.unidades }
      })
      return parseFloat(total.toFixed(2))
    },
    goToAlbaran(idPedido) {
      this.$router.push({ name: 'albaran-edit', params: { id: idPedido } })
    },
    imprimirPedido(id) {
      this.$store
        .dispatch("app-pedidos/printPedido", id)
        .then((response) => {
          window.open(response.route, "_blank")
        })
        .catch((error) => {
          console.error("Error printing the pedido:", error)
        });
    },
    createAlbaran() {
      const albaranDetalles = []
      this.pedidoData.detalles.forEach(pD => {
        if (!pD.ignored) {
          // Create albaran only with approved and not ignored materiales
          albaranDetalles.push(
            new AlbaranDetalle({
              id: null,
              fk_material: pD.fk_material,
              fk_albaran: null,
              descripcion: pD.descripcion,
              unit_entregadas: 0,
              unit_solicitadas: pD.unidades,
            }),
          )
        }
      })

      const albaran = new Albaran({
        fk_pedido: this.pedidoData.id,
        fk_user_creado: this.pedidoData.fk_user_creado,
        fecha: this.pedidoData.fecha,
        estado: 'pendiente',
        fk_empleado_recoge: null,
        nota: this.pedidoData.nota,
        detalles: albaranDetalles,
        fk_user_update: this.user.id,
        id: null,
      })

      this.$store.dispatch('app-albaranes/addAlbaran', albaran).then(val => {
        this.pedidoData.fk_albaran = val.id
        this.savePedido()
        this.$router.push({ name: 'albaran-edit', params: { id: val.id } })
      })
    },
    async saveAndApprovePedido() {
      try {

        await this.savePedido();
        await this.approvePedido();

      } catch (error) {
        console.error('Error al guardar y aprobar el pedido:', error);
        // Muestra un mensaje de error
        this.$bvToast.toast('Error al guardar y aprobar el pedido', {
          title: 'Error',
          variant: 'danger',
          solid: true
        });
      }
    },
    approvePedido() {
      const { id } = this.$route.params
      const estado = 'aceptado'
      const approved_by = this.user.id
      const detallePedidos = this.pedidoItems

      this.$store
        .dispatch('app-pedidos/changeStatus', { id, estado, approved_by, detallePedidos })
        .then(() => {
          this.refreshComponentData()
        })
    },/*
    revertStatusPedido() {
      const { id } = this.$route.params
      const estado = 'pendiente'
      const approved_by = null
      this.$store
        .dispatch('app-pedidos/changeStatus', { id, estado, approved_by })
        .then(() => {
          this.refreshComponentData()
        })
    },*/
    denyPedido() {
      const { id } = this.$route.params
      const estado = 'cancelado'
      this.$store
        .dispatch('app-pedidos/changeStatus', { id, estado })
        .then(() => {
          this.$router.push({ name: 'pedidos-list' })
        })
    },
    async sendPedidoToEvaluation() {
      await this.savePedido()
      const { id } = this.$route.params
      const estado = 'pendiente'
      this.$store
        .dispatch('app-pedidos/changeStatus', { id, estado })
        .then(() => {
          this.$router.push({ name: 'pedidos-list' })
        })
    },
    deletePedido(id) {
      if (id === 'new') this.$router.push({ name: 'pedidos-list' })
      else {
        this.$store.dispatch('app-pedidos/deletePedido', id).then(() => {
          this.$router.push({ name: 'pedidos-list' })
        })
      }
    },
    statusLabel(status) {
      return pedidos.estados.find(el => el.value === status).label
    },
    statusVariant(status) {
      return pedidos.estados.find(el => el.value === status).variant
    },
    addNewItemInItemForm() {
      this.selected.forEach(item => {
        this.pedidoItems.push(item)
      })
      this.materiales.map(m => (m.selected = false))
      this.selected = []
      this.searchMaterial = ''
      this.selectMaterialModal = false
    },
    removeItem(index, item) {
      // If it was a product added as a draft, and not as pending
      if (item.id && this.pedidoData.estado != 'borrador') {
        this.pedidoItems[index].ignored = true
        const indexDet = this.pedidoData.detalles.findIndex(
          el => el.id === item.id,
        )
        this.pedidoData.detalles[indexDet].ignored = true
      } else {
        this.pedidoItems.splice(index, 1)
        const indexDet = this.pedidoData.detalles.findIndex(
          el => el.id === item.id,
        )
        this.pedidoData.detalles.splice(indexDet, 1)
      }
    },
    async onSubmit() {
      await this.savePedido().then(() => {
        this.$router.push({ name: 'pedidos-list' })
      })
    },
    async refreshComponentData() {
      // Vuelve a cargar los datos del pedido
      const updatedPedido = await this.$store.dispatch('app-pedidos/fetchPedido', this.pedidoData.id)
      this.pedidoData = updatedPedido

      // Actualiza pedidoItems
      this.pedidoItems = []
      for (const detalle of this.pedidoData.detalles) {
        const material = await this.$store.dispatch('app-materiales/fetchMaterial', detalle.fk_material)
        this.pedidoItems.push({
          id: material.id,
          pedidoItemId: detalle.id,
          refProd: material.codigo,
          unidades: detalle.unidades,
          descripcion: detalle.descripcion,
          descripcion_custom: detalle.descripcion_custom,
          medida: material.medida,
          talla: material.talla,
          coste: material.coste,
          fk_proveedor: detalle.fk_proveedor,
          stock: material.stock,
          fk_pedido_proveedor: detalle.fk_pedido_proveedor,
          pedido_proveedor_codigo: detalle.pedido_proveedor_codigo,
          ignored: detalle.ignored ?? false,
        })
      }
    },
  },
  setup() {
    const PEDIDO_APP_STORE_MODULE_NAME = 'app-pedidos'
    const DEPARTAMENTO_APP_STORE_MODULE_NAME = 'app-departamento'
    const MATERIALES_APP_STORE_MODULE_NAME = 'app-materiales'
    const ALBARENES_APP_STORE_MODULE_NAME = 'app-albaranes'
    const PEDIDO_PROVEEDOR_APP_STORE_MODULE_NAME = 'app-pedidos-proveedores'
    const PROVEEDOR_APP_STORE_MODULE_NAME = 'app-proveedor'

    // Register module
    if (!store.hasModule(PEDIDO_APP_STORE_MODULE_NAME)) store.registerModule(PEDIDO_APP_STORE_MODULE_NAME, pedidosStoreModule)

    if (!store.hasModule(DEPARTAMENTO_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        DEPARTAMENTO_APP_STORE_MODULE_NAME,
        departamentoStoreModule,
      )
    }

    if (!store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MATERIALES_APP_STORE_MODULE_NAME,
        materialesStoreModule,
      )
    }

    if (!store.hasModule(ALBARENES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        ALBARENES_APP_STORE_MODULE_NAME,
        albaranesStoreModule,
      )
    }

    if (!store.hasModule(PEDIDO_PROVEEDOR_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PEDIDO_PROVEEDOR_APP_STORE_MODULE_NAME,
        pedidosProveedoresStoreModule,
      )
    }

    if (!store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROVEEDOR_APP_STORE_MODULE_NAME,
        proveedorStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PEDIDO_APP_STORE_MODULE_NAME)) store.unregisterModule(PEDIDO_APP_STORE_MODULE_NAME)

      if (store.hasModule(DEPARTAMENTO_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTAMENTO_APP_STORE_MODULE_NAME)

      if (store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIALES_APP_STORE_MODULE_NAME)

      if (store.hasModule(ALBARENES_APP_STORE_MODULE_NAME)) store.unregisterModule(ALBARENES_APP_STORE_MODULE_NAME)

      if (store.hasModule(PEDIDO_PROVEEDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(PEDIDO_PROVEEDOR_APP_STORE_MODULE_NAME)

      if (store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(PROVEEDOR_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    const departamentos = ref(null)
    const proveedores = ref([])

    const { listDepartamentos } = useDepartamentosList()

    listDepartamentos().then(response => {
      departamentos.value = response
    })

    const { listProveedores } = useProveedoresList()

    listProveedores().then(response => {
      proveedores.value = response
    })

    const materiales = ref(null)

    const { listMateriales, resolveStatusText, resolveStatusVariant } = useMaterialesList()

    listMateriales().then(response => {
      materiales.value = response
    })

    const itemFormBlankItem = ref({
      id: '',
      refProd: '',
      unidades: 1,
      descripcion: '',
      descripcion_custom: '',
      medida: '',
      talla: '',
      coste: 0,
      ignored: false,
    })

    const pedidoData = ref(
      new Pedido({
        total_coste: 0,
        estado: 'borrador',
        detalles: [],
        fecha: new Date().toISOString().split('T')[0],
      }),
    )
    const pedidoItems = ref([])

    const updateItemForm = (index, val) => {
      const {
        codigo,
        id,
        medida,
        talla,
        descripcion,
        descripcion_custom,
        coste,
        stock,
        fk_proveedor,
        fk_pedido_proveedor,
        pedido_proveedor_codigo,
        ignored,
      } = val
      pedidoItems.value[index].stock = stock
      pedidoItems.value[index].refProd = codigo
      pedidoItems.value[index].id = id
      pedidoItems.value[index].descripcion = descripcion
      pedidoItems.value[index].descripcion_custom = descripcion_custom
      pedidoItems.value[index].medida = medida
      pedidoItems.value[index].talla = talla
      pedidoItems.value[index].coste = coste
      pedidoItems.value[index].fk_proveedor = fk_proveedor
      pedidoItems.value[index].fk_pedido_proveedor = fk_pedido_proveedor
      pedidoItems.value[index].pedido_proveedor_codigo = pedido_proveedor_codigo
      pedidoItems.value[index].ignored = ignored
    }

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch('app-pedidos/fetchPedido', router.currentRoute.params.id)
        .then(async response => {
          pedidoData.value = response
          const { detalles } = pedidoData.value
          for (let i = 0; i < detalles.length; i++) {
            const material = await store.dispatch(
              'app-materiales/fetchMaterial',
              detalles[i].fk_material,
            )
            pedidoItems.value.push({
              id: material.id,
              pedidoItemId: detalles[i].id,
              refProd: material.codigo,
              unidades: detalles[i].unidades,
              descripcion: detalles[i].descripcion,
              descripcion_custom: detalles[i].descripcion_custom,
              medida: material.medida,
              talla: material.talla,
              coste: material.coste,
              fk_proveedor: detalles[i].fk_proveedor,
              stock: material.stock,
              fk_pedido_proveedor: detalles[i].fk_pedido_proveedor,
              pedido_proveedor_codigo: detalles[i].pedido_proveedor_codigo,
              ignored: detalles[i].ignored ?? false,
            })
          }
        })
    }


    const tableHeaders = ref([
      { key: 'actions', label: '', thStyle: { maxWidth: '65px', paddingLeft: '10px' } },
      {
        key: 'descripcion', label: 'Descripcion', sortable: false, thStyle: { maxWidth: '500px' },
      },
      { key: 'medida', label: 'Tipo Medida', sortable: false },
      { key: 'unidades', label: 'Cantidad', sortable: false },
    ])

    // Display Coste only if it is admin
    if (store.state.auth.user.fk_role === 1) {
      tableHeaders.value.push(
        { key: 'stock', label: 'Stock', sortable: false },
        { key: 'coste', label: 'Coste Unit.' },
        { key: 'coste_total', label: 'Coste Total.' },
        { key: 'fk_pedido_proveedor', label: 'Ped. Proveedor' },
      )
    }

    const resetPedidoData = () => {
      pedidoData.value = JSON.parse(
        JSON.stringify(
          new Pedido({
            total_coste: 0,
            estado: 'borrador',
            detalles: [],
          }),
        ),
      )
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetPedidoData)

    return {
      pedidoData,
      updateItemForm,
      itemFormBlankItem,
      pedidoItems,
      listDepartamentos,
      departamentos,
      listMateriales,
      materiales,
      tableHeaders,
      refFormObserver,
      getValidationState,
      resetForm,
      resolveStatusText,
      resolveStatusVariant,
      proveedores,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.select-departamentos .vs__dropdown-menu {
  width: 500px;
}
.detalles .form-group {
  margin-bottom: unset;
}
.detalles td {
  padding: 0.72rem 0 0.72rem 1.5rem;
}
.select-material-movil {
  width: 360px
}
</style>
