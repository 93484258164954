class PedidoDetalle {
    constructor({ id, fk_pedido, pedido_proveedor_codigo, fk_pedido_proveedor, fk_material, unidades, coste_unit, descripcion, descripcion_custom, ignored } = {}) {
        this.id = id
        this.fk_pedido = fk_pedido
        this.fk_material = fk_material
        this.unidades = unidades
        this.coste_unit = coste_unit
        this.descripcion = descripcion
        this.descripcion_custom = descripcion_custom
        this.fk_pedido_proveedor = fk_pedido_proveedor
        this.pedido_proveedor_codigo = pedido_proveedor_codigo
        this.ignored = ignored
    }
}

export default PedidoDetalle